export const EXTERNAL_LINKS = {
    memsql: "https://www.memsql.com/",

    forums: "https://www.memsql.com/forum/",

    "about-studio":
        "https://docs.memsql.com/studio-redir/memsql-studio-overview/",

    "advanced-counters":
        "https://docs.memsql.com/studio-redir/advanced-statistics/",

    "create-database": "https://docs.memsql.com/studio-redir/create-database/",

    "create-table": "https://docs.memsql.com/studio-redir/create-table/",

    "create-index": "https://docs.memsql.com/studio-redir/create-index/",

    "create-view": "https://docs.memsql.com/studio-redir/create-view/",

    "create-pipeline": "https://docs.memsql.com/studio-redir/create-pipeline/",

    "create-stored-procedure":
        "https://docs.memsql.com/studio-redir/create-procedure/",

    "create-udf": "https://docs.memsql.com/studio-redir/create-udf/",

    "create-udaf": "https://docs.memsql.com/studio-redir/create-udaf/",

    "load-data": "https://docs.memsql.com/studio-redir/load-data/",

    "lmv-events": "https://docs.memsql.com/studio-redir/lmv-events/",

    "release-notes": "https://docs.memsql.com/studio-redir/release-notes/",

    "terms-conditions": "https://www.memsql.com/legal",

    "cluster-architecture":
        "https://docs.memsql.com/studio-redir/cluster-architecture",

    "memory-management":
        "https://docs.memsql.com/studio-redir/memory-management",

    "pipelines-overview":
        "https://docs.memsql.com/studio-redir/pipelines-overview",

    EXPLAIN: "https://docs.memsql.com/studio-redir/explain",

    PROFILE: "https://docs.memsql.com/studio-redir/profile",

    licensing: "https://docs.memsql.com/studio-redir/licensing",

    chrome: "https://www.google.com/chrome/",

    firefox: "https://www.mozilla.org/firefox",

    "studio-resource-usage":
        "https://docs.memsql.com/studio-redir/studio-resource-usage/",

    "studio-release-notes":
        "https://docs.memsql.com/studio-redir/studio-release-notes/",

    // This link takes users to the MemSQL Forums with a prefilled topic, body
    // and category "MemSQL Studio".
    "forums-feedback":
        "https://www.memsql.com/forum/new-topic?title=topic%20title&category=MemSQL%20Studio&body=topic%20body",

    "memsql-support": "https://support.memsql.com/hc/en-us/requests/new",

    "license-unit-definition":
        "https://docs.memsql.com/studio-redir/license-unit-definition",

    "upgrade-studio": "https://docs.memsql.com/studio-redir/studio-upgrade",

    "pipeline-transforms":
        "https://docs.memsql.com/studio-redir/pipelines-transforms/",

    "pipeline-extractors":
        "https://docs.memsql.com/studio-redir/pipelines-extractors/",

    "parallel-loading":
        "https://docs.memsql.com/studio-redir/pipelines-parallelized-loading/",

    "code-generation": "https://docs.memsql.com/studio-redir/code-generation/",

    "tpch-benchmark":
        "http://www.tpc.org/tpc_documents_current_versions/pdf/tpc-h_v2.17.1.pdf",

    "database-states": "https://docs.memsql.com/studio-redir/database-states/",

    "explain-stats-warning":
        "https://docs.memsql.com/studio-redir/explain-stats-warning/",

    "explain-mismatched-types-warning":
        "https://docs.memsql.com/studio-redir/explain-mismatched-types-warning/",
};

export type LinkName = keyof typeof EXTERNAL_LINKS;
