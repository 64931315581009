import UAParser from "ua-parser-js";

const uaParser = new UAParser();

export function isSupportedBrowser(): boolean {
    const browser = uaParser.getBrowser().name;

    return (
        browser === "Chrome" || browser === "Firefox" || browser === "Chromium"
    );
}

export function isMobileDevice(): boolean {
    // device.type is undefined on non-mobile/tablet devices
    return Boolean(uaParser.getDevice().type);
}

export function isSupportedDevice(): boolean {
    return !isMobileDevice() && isSupportedBrowser();
}
