import { Direction } from "view/components/overlay";

import * as React from "react";
import classnames from "classnames";
import _ from "lodash";

import Overlay from "view/components/overlay";

export const DropdownContext = React.createContext({ close: () => {} });

export type DropdownDirection = Direction;
export type DropdownState = "OPEN" | "CLOSED";

type Props = {
    direction: DropdownDirection;
    className?: string;
    spacing: number;

    // Whether or not the dropdown is open
    open: DropdownState;

    // The dropdown's control element
    control: React.ReactElement<unknown>;

    // The contents of the dropdown
    children: React.ReactNode;

    // Called when we open or close
    onChange: (state: DropdownState) => void;

    closeOnMenuItemClick: boolean;
};

export default class Dropdown extends React.Component<Props> {
    static defaultProps: Partial<Props> = {
        closeOnMenuItemClick: true,
    };

    componentWillMount() {
        if (this.props.open === "OPEN") {
            this.handleOpen();
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.open === "OPEN" && this.props.open !== nextProps.open) {
            this.handleOpen();
        }
    }

    handleOpen = () => {
        this.props.onChange("OPEN");
    };

    handleClose = () => {
        this.props.onChange("CLOSED");
    };

    render() {
        const {
            open,
            children,
            control,
            direction,
            spacing,
            className,
            closeOnMenuItemClick,
        } = this.props;

        let menuItemClose;
        if (closeOnMenuItemClick) {
            menuItemClose = this.handleClose;
        } else {
            menuItemClose = _.noop;
        }

        return (
            <DropdownContext.Provider value={{ close: menuItemClose }}>
                <Overlay
                    className={classnames("components-dropdown", className)}
                    overlayClassName="components-dropdown-overlay"
                    visible={open === "OPEN"}
                    direction={direction}
                    spacing={spacing}
                    role="button"
                    tabIndex={0}
                    layer="dropdown"
                    onClickOverlay={this.handleClose}
                    overlay={children}
                >
                    {control}
                </Overlay>
            </DropdownContext.Provider>
        );
    }
}
