import { Maybe } from "util/maybe";
import { LinkName } from "urls";
import { DropdownState } from "view/components/dropdown";

import * as React from "react";
import classnames from "classnames";
import _ from "lodash";

import Icon, { IconType } from "view/components/icon";
import Dropdown from "view/components/dropdown";
import ExtLink from "view/components/external-link";

import Key from "util/key";

import "./button.scss";

type DropdownProps = {
    wrapperClassName?: string;
    dropdownContent: React.ReactNode;
};

type BaseProps<T> = T &
    (DropdownProps | {}) & {
        className?: string;
        children?: React.ReactNode;

        // if passed, render an iconbutton
        icon?: string;
        iconType?: IconType;

        // style options
        primary?: boolean;
        disabled?: boolean;
        ghost?: boolean;
        danger?: boolean;
        active?: boolean;

        // size options
        large?: boolean;
        small?: boolean;

        // options
        fullWidth?: boolean;
        marginLeft?: boolean;
        marginRight?: boolean;

        tabIndex?: number;
    };

type ExternalLinkProps = {
    // externalLink is an external link to navigate to
    externalLink: LinkName;
    category: string;
};

export type ButtonClickEvent =
    | React.MouseEvent<HTMLAnchorElement>
    | React.KeyboardEvent<HTMLAnchorElement>;

type OnClickProps = {
    // onClick will be called if the user left clicks the button without
    // a modifier key
    onClick?: (evt: ButtonClickEvent) => void;
    onMouseDown?: (evt: React.MouseEvent<HTMLAnchorElement>) => void;
};

type ButtonExternalLinkProps = BaseProps<ExternalLinkProps>;
export type ButtonOnClickProps = BaseProps<OnClickProps>;

type Props = ButtonExternalLinkProps | ButtonOnClickProps;

type State = {
    dropdownState: DropdownState;
};

export class Button extends React.PureComponent<Props, State> {
    $el: Maybe<HTMLAnchorElement>;

    state: State = {
        dropdownState: "CLOSED",
    };

    handleDropdownToggle = () =>
        this.setState(({ dropdownState }) => ({
            dropdownState: dropdownState === "CLOSED" ? "OPEN" : "CLOSED",
        }));

    handleDropdownChange = (dropdownState: DropdownState) =>
        this.setState({ dropdownState });

    handleClick = (
        evt:
            | React.MouseEvent<HTMLAnchorElement>
            | React.KeyboardEvent<HTMLAnchorElement>
    ) => {
        const { disabled } = this.props;
        const externalLink = (this.props as BaseProps<ExternalLinkProps>)
            .externalLink;
        const onClick = (this.props as BaseProps<OnClickProps>).onClick;

        if (this.$el) {
            this.$el.blur();
        }

        if (disabled && externalLink) {
            evt.preventDefault();
        }

        if (!disabled) {
            evt.stopPropagation();

            if (onClick && _.isFunction(onClick)) {
                onClick(evt);
            }
        }
    };

    handleKeyPress = (evt: React.KeyboardEvent<HTMLAnchorElement>) => {
        if (evt.charCode === Key.ENTER) {
            this.handleClick(evt);
        }
    };

    render() {
        const {
            className,
            primary,
            disabled,
            ghost,
            danger,
            active,
            large,
            small,
            marginLeft,
            marginRight,
            fullWidth,
            tabIndex,
            icon,
            iconType,
        } = this.props;

        const { category, externalLink } = this.props as BaseProps<
            ExternalLinkProps
        >;
        const onMouseDown = (this.props as BaseProps<OnClickProps>).onMouseDown;

        let { children } = this.props;

        const { dropdownState } = this.state;

        const classes = classnames(
            {
                "components-button": true,

                // secondary is the default button style
                secondary: !primary && !ghost && !danger,

                // styles
                primary,
                disabled,
                ghost,
                danger,
                active,

                // sizes
                large,
                small,

                // options
                "left-margin": marginLeft,
                "right-margin": marginRight,
                "full-width": fullWidth,

                // with dropdown content
                "dropdown-button":
                    "dropdownContent" in this.props &&
                    Boolean(this.props.dropdownContent),
            },
            className
        );

        if (icon) {
            children = <Icon fixedWidth icon={icon} iconType={iconType} />;
        }

        let button;
        const buttonProps = {
            tabIndex: tabIndex || 0,
            className: classes,
            children,
            onClick: this.handleClick,
            onKeyPress: this.handleKeyPress,
            role: "button",
            onMouseDown,
        };

        if (externalLink) {
            const extLinkProps: React.ComponentProps<typeof ExtLink> = {
                ...buttonProps,
                name: externalLink,
                category,
                setRef: el => (this.$el = el || undefined),
                underlineOnHover: false,
            };

            button = <ExtLink {...extLinkProps} />;
        } else {
            button = (
                <a {...buttonProps} ref={el => (this.$el = el || undefined)} />
            );
        }

        if (
            "dropdownContent" in this.props &&
            Boolean(this.props.dropdownContent)
        ) {
            const dropdownClasses = classnames(
                "components-button-with-dropdown",
                this.props.wrapperClassName
            );

            return (
                <div className={dropdownClasses}>
                    {button}

                    <Dropdown
                        spacing={5}
                        control={
                            <Button
                                large={large}
                                primary={primary}
                                disabled={disabled}
                                className="button-dropdown-control"
                                icon="chevron-down"
                                onClick={this.handleDropdownToggle}
                            />
                        }
                        onChange={this.handleDropdownChange}
                        open={dropdownState}
                        direction="sw"
                    >
                        {this.props.dropdownContent}
                    </Dropdown>
                </div>
            );
        }

        return button;
    }
}
