// has to be in sync with variables.scss!

export default {
    base: 0,
    menu: 500,
    banner: 800,
    toolbar: 900,
    "bottom-panel": 900,
    header: 1000,
    "drag-handle": 2000,
    scrollbar: 2100,
    dropdown: 2200,
    tutorial: 2800,
    modal: 3000,
    toast: 3200,
    overlay: 3500,
    tooltip: 4000,
};
