import { Maybe } from "util/maybe";

import * as React from "react";
import * as ReactDOM from "react-dom";

type Props = {
    children: React.ReactNode;
    active?: boolean;
};

export default class Portal extends React.Component<Props> {
    targetEl: Maybe<HTMLElement>;

    componentWillMount() {
        let bodys = document.getElementsByTagName("body");
        if (bodys.length !== 1) {
            console.error("zero or more than one body element found");
        } else {
            this.targetEl = bodys[0];
        }
    }

    render() {
        const { children, active } = this.props;
        const targetEl = this.targetEl;

        if (targetEl && active) {
            return ReactDOM.createPortal(children, targetEl);
        } else {
            return null;
        }
    }
}
