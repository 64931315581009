declare var STUDIO_COMMIT_HASH: string;
declare var STUDIO_VERSION: string;

import { Event as SentryEvent, Breadcrumb } from "@sentry/types";

import * as SentryBrowser from "@sentry/browser";

import * as logging from "util/logging";

const SENTRY_DSN = "https://9590d00c340748f197bca2ab1a3d9194@sentry.io/288378";

// This method returns whether an error is coming from the search method of
// monaco editor or not.
// We want to filter this specific error since the stacktrace might contain
// user data.
// More details in: PLAT-3117
const isMonacoSearchRegexError = (event: SentryEvent) => {
    if (event.exception && event.exception.values) {
        // Get the first exception value.
        const exceptionValue = event.exception.values[0];

        if (exceptionValue && exceptionValue.stacktrace) {
            const { frames } = exceptionValue.stacktrace;

            if (frames && frames.length > 1) {
                // Get the last and the second last frame of the exception
                // stacktrace.
                const lastFrame = frames[frames.length - 1];
                const secondLastFrame = frames[frames.length - 2];

                // Return true if the lastFrame function name is `RegExp.exec`
                // and if the secondLastFrame function is the Monaco one.
                return (
                    lastFrame.function === "RegExp.exec" &&
                    secondLastFrame.function &&
                    secondLastFrame.function.includes("Searcher.next")
                );
            }
        }
    }

    return false;
};

// We limit the maximum number of Sentry events per session to avoid going over
// our Sentry event limit.
const MAX_SENTRY_EVENTS_SESSION = 10;
let sentryEventsReported = 0;

export default class Sentry {
    static setup() {
        // We set the Sentry release as the current commit hash.
        // This allows us to have issues tagged to a specific release
        // in Sentry.
        SentryBrowser.init({
            dsn: SENTRY_DSN,
            release: STUDIO_COMMIT_HASH,
            environment: process.env.NODE_ENV,
            beforeSend: event => {
                if (isMonacoSearchRegexError(event)) {
                    return null;
                }

                if (sentryEventsReported >= MAX_SENTRY_EVENTS_SESSION) {
                    logging.log(
                        "warn",
                        "Sentry event detected, but Sentry event limit for this session has been reached.",
                        event
                    );
                    return null;
                }

                sentryEventsReported++;

                if (process.env.NODE_ENV === "production") {
                    logging.log(
                        "info",
                        "[Production] Sending Sentry issue",
                        event
                    );
                    return event;
                } else {
                    logging.log(
                        "info",
                        "[Development] Sending Sentry issue",
                        event
                    );
                }

                return null;
            },
        });

        this.setStudioVersion(STUDIO_VERSION);
    }

    static setLicenseKey(licenseKey: string) {
        SentryBrowser.configureScope(scope => {
            scope.setTag("license_key", licenseKey);
        });
    }

    static setMemsqlVersion(memsqlVersion: string) {
        SentryBrowser.configureScope(scope => {
            scope.setTag("memsql_version", memsqlVersion);
        });
    }

    static setStudioVersion(studioVersion: string) {
        SentryBrowser.configureScope(scope => {
            scope.setTag("studio_version", studioVersion);
        });
    }

    static setClusterHostname(hostname: string) {
        SentryBrowser.configureScope(scope => {
            scope.setTag("cluster_hostname", hostname);
        });
    }

    static setClusterPort(port: number) {
        SentryBrowser.configureScope(scope => {
            scope.setTag("cluster_port", String(port));
        });
    }

    static captureBreadcrumb(crumb: Breadcrumb) {
        SentryBrowser.addBreadcrumb(crumb);
    }

    static captureException(err: Error) {
        SentryBrowser.captureException(err);
    }
}
