import * as React from "react";
import * as ReactDOM from "react-dom";
import classnames from "classnames";

import Portal from "view/components/portal";

import ZIndex from "util/z-index";

import "./overlay.scss";

// Direction corresponds to compass directions
export type Direction = "n" | "ne" | "e" | "se" | "s" | "sw" | "w" | "nw";

// These layers correspond to z-index layers (4 > 3 > 2 > 1):
// * 4 — tooltip
// * 3 — modal
// * 2 — dropdown
// * 1 — base (default)
type OverlayLayer = "base" | "dropdown" | "modal" | "tooltip";

type Props = {
    className?: string;
    overlayClassName?: string;
    visible?: boolean;
    direction: Direction;
    spacing: number;
    minWidth?: number;
    overlay: React.ReactNode;
    // only triggered if the user clicked on the actual overlay
    onClickOverlay?: () => void;
    children: React.ReactNode;
    layer: OverlayLayer;

    [key: string]: any;
};

type State = {
    style: {
        // each of the following is a CSS property
        top?: string | number;
        left?: string | number;
        bottom?: string | number;
        right?: string | number;
        transform?: string;
    };
};

export default class Overlay extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { style: Object.freeze({}) };
    }

    static defaultProps = {
        layer: 1,
    };

    componentDidMount() {
        if (this.props.visible) {
            this.handleShow();
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.visible !== nextProps.visible && nextProps.visible) {
            this.handleShow();
        }
    }

    getBounds = () => {
        let $el = ReactDOM.findDOMNode(this);
        if ($el && $el instanceof HTMLElement) {
            return $el.getBoundingClientRect();
        }
    };

    handleShow = () => {
        const bounds = this.getBounds();

        if (bounds) {
            let { direction, spacing } = this.props;

            let horizontalStyle; // left/right CSS styles to control horizontal positioning
            let verticalStyle; // top/bottom CSS styles to control vertical positioning
            let transform;

            if (direction[0] === "n" || direction[0] === "s") {
                if (direction[0] === "n") {
                    // put overlay's bottom edge {spacing}px above main element
                    verticalStyle = {
                        bottom: `calc(100% - ${bounds.top - spacing}px)`,
                    };
                } else {
                    // put overlay's top edge {spacing}px below main element
                    verticalStyle = { top: bounds.bottom + spacing };
                }

                if (direction.length > 1) {
                    if (direction[1] === "e") {
                        // line up overlay's left edge with main element's left edge
                        horizontalStyle = { left: bounds.left };
                    } else if (direction[1] === "w") {
                        // line up overlay's right edge with main element's right edge
                        horizontalStyle = {
                            right: `calc(100% - ${bounds.right}px)`,
                        };
                    }
                } else {
                    // line up overlay's center and main element's center vertically
                    horizontalStyle = { left: bounds.left + bounds.width / 2 };
                    transform = "translateX(-50%)";
                }
            } else {
                // line up overlay's center and main element's center horizontally
                verticalStyle = { top: bounds.top + bounds.height / 2 };
                transform = "translateY(-50%)";

                if (direction === "e") {
                    // put overlay's left edge {spacing}px to the right of main element
                    horizontalStyle = { left: bounds.right + spacing };
                } else if (direction === "w") {
                    // put overlay's right edge {spacing}px to the left of main element
                    horizontalStyle = {
                        right: `calc(100% - ${bounds.left - spacing}px)`,
                    };
                }
            }

            this.setState({
                style: { transform, ...horizontalStyle, ...verticalStyle },
            });
        }
    };

    handleClickOverlay = (evt: React.MouseEvent) => {
        // only trigger onClickOverlay if the user clicked the actual overlay
        if (this.props.onClickOverlay && evt.currentTarget === evt.target) {
            this.props.onClickOverlay();
        }
    };

    render() {
        const {
            className,
            overlayClassName,
            children,
            visible,
            overlay,
            minWidth,
            // We hold on to `onClickOverlay` so as not to pass it other
            // `...otherProps`.
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onClickOverlay,
            layer,
            ...otherProps
        } = this.props;
        const { style } = this.state;

        const classes = classnames("components-overlay", className);

        const outerClasses = classnames(
            "components-overlay-portal",
            overlayClassName
        );

        const contentWrapStyle = { ...style, minWidth };
        const contentWrapClasses = classnames("overlay-content-wrap");

        return (
            <div className={classes} {...otherProps}>
                {children}
                <Portal active={visible}>
                    <div
                        className={outerClasses}
                        style={{ zIndex: ZIndex[layer] }}
                        onClick={this.handleClickOverlay}
                    >
                        <div
                            className={contentWrapClasses}
                            style={contentWrapStyle}
                        >
                            {overlay}
                        </div>
                    </div>
                </Portal>
            </div>
        );
    }
}
