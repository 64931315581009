import { Maybe } from "util/maybe";

import { log } from "util/logging";

export type EventProperties = {
    [trait: string]: Maybe<string | number | boolean>;
};

export const track = (action: string, properties?: EventProperties) => {
    const actionName = `studio-${action}`;

    window.analytics.ready(() => {
        window.analytics.track(actionName, properties);
        log(
            "debug",
            `Analytics: ${actionName} - ${JSON.stringify(properties)}`
        );
    });
};

export const trackBlurEvent = (formName: string) => (
    field: string,
    success?: boolean
) => {
    let action;
    if (success === undefined) {
        action = "form-blur";
    } else {
        action = success
            ? "form-blur-validation-success"
            : "form-blur-validation-error";
    }

    track(action, {
        "form-id": formName,
        "field-name": field,
    });
};

export const trackFormSubmit = (formID: string, success: boolean) => {
    track("form-submission", {
        "form-id": formID,
        "submission-status": success,
    });
};

export const trackInternalLinkClick = (
    category: string,
    properties?: Record<string, string>
) => {
    track("link-click", {
        category,
        ...properties,
    });
};

export const trackExternalLinkClick = (
    category: string,
    properties?: Record<string, string>
) => {
    track("external-link-click", {
        category,
        ...properties,
    });
};

export const trackReload = (category: string) => {
    track("click-reload", {
        category,
    });
};

export const identify = (
    userID: string,
    traits: Record<string, string | number | boolean>
) => {
    window.analytics.ready(() => {
        window.analytics.identify(userID, traits);
        log(
            "debug",
            `Analytics: Identify - ${JSON.stringify(traits, null, 2)}`
        );
    });
};

export const page = (route: string) => {
    window.analytics.ready(() => window.analytics.page(route));
};
