import {
    IconProp,
    IconName as FAIconName,
    IconPrefix,
} from "@fortawesome/fontawesome-svg-core";

import * as React from "react";
import classnames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";

import CustomIcons from "assets/icons";

library.add(fas, far, CustomIcons);

import "./icon.scss";

// This type is meant to be used with reactified Icons by react-inline-svg
// In other words, SVGs with the name: *.inline.svg
export type InlineSVG = React.ComponentType<{
    width?: number;
    height?: number;
}>;

// The "undefined" size is the default size for Font Awesome icons
export type IconSize =
    | "xs"
    | "sm"
    | "lg"
    | "2x"
    | "3x"
    | "4x"
    | "5x"
    | "6x"
    | "7x"
    | "8x"
    | "9x"
    | "10x";

const FONT_AWESOME_TYPES: { [key: string]: IconPrefix } = {
    regular: "far",
    solid: "fas",
    light: "fal",
    brand: "fab",
};

export type IconType = keyof typeof FONT_AWESOME_TYPES;
export type IconName = FAIconName;

export type IconRepr = {
    icon: string;
    iconType?: IconType;
};

export type IconProps = {
    icon: string;

    iconType?: IconType;

    className?: string;

    // use these to select a contextual text color
    primary?: boolean;
    success?: boolean;
    warning?: boolean;
    error?: boolean;
    disabled?: boolean;

    fixedWidth?: boolean;
    size?: IconSize;

    // common pattern of adding 5px to the left of an icon
    leftMargin?: boolean;

    // common pattern of adding 5px to the right of an icon
    rightMargin?: boolean;

    [key: string]: any;
};

export default class Icon extends React.PureComponent<IconProps> {
    render() {
        const {
            className,
            primary,
            success,
            warning,
            icon,
            iconType,
            error,
            disabled,
            rightMargin,
            leftMargin,
            ...unknownProps
        } = this.props;

        const classes = classnames(
            {
                "components-icon": true,
                "components-icon-primary": primary,
                "components-icon-success": success,
                "components-icon-warning": warning,
                "components-icon-error": error,
                "components-icon-disabled": disabled,
                "right-margin": rightMargin,
                "left-margin": leftMargin,
            },
            className
        );

        // We use type assertions here since we can't extend IconName to support
        // custom icon names.
        let iconProp: IconProp = icon as IconName;
        if (iconType) {
            iconProp = [FONT_AWESOME_TYPES[iconType], icon as IconName];
        }

        return (
            <FontAwesomeIcon
                icon={iconProp}
                {...unknownProps}
                className={classes}
            />
        );
    }
}
