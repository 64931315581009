import Sentry from "util/sentry";

export class NonLoggableError extends Error {}

// The `logError` method is used to log errors. Right now there are two types
// of errors - the ones that can be logged to Sentry and the ones that can't.
// Some errors may have user data or are too noisy for us to have in Sentry and
// that's why we don't log them. Every error that cannot be logged to Sentry
// should be an instance of `NonLoggableError`.
//
// Any other errors are assumed to be JavaScript runtime errors, and those get
// logged to Sentry.
export function logError(error: NonLoggableError | Error, message?: string) {
    if (message) {
        log("error", message, error);
    } else {
        log("error", error);
    }

    if (!(error instanceof NonLoggableError)) {
        Sentry.captureException(error);
    }
}

type LogLevel = "info" | "error" | "warn" | "debug";

export function log(level: LogLevel, ...args: Array<unknown>) {
    if (level === "info") {
        console.log(...args);
    } else if (level === "error") {
        console.error(...args);
    } else if (level === "warn") {
        console.warn(...args);
    } else if (level === "debug") {
        if (process.env.NODE_ENV === "development") {
            console.log("[Debug]", ...args);
        }
    }
}
