import { LinkName } from "urls";

import * as React from "react";
import classnames from "classnames";

import { EXTERNAL_LINKS } from "urls";
import * as analytics from "util/segment";

import "./external-link.scss";

export type ExternalLinkInfo = {
    name: LinkName;
    category: string;
};

type Props = ExternalLinkInfo &
    Partial<typeof HTMLAnchorElement> & {
        children?: React.ReactNode;
        className?: string;
        setRef?: (el: HTMLAnchorElement) => void;
        underlineOnHover?: boolean;
        onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    };

export default class ExtLink extends React.Component<Props> {
    static defaultProps = {
        underlineOnHover: true,
    };

    handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        const { name, category, onClick } = this.props;

        analytics.trackExternalLinkClick(category, {
            url: EXTERNAL_LINKS[name],
        });

        onClick && onClick(event);
    };

    render() {
        // We hold on to `category` so as not to pass it to `...rest`.
        const {
            name,
            children,
            className,
            setRef,
            underlineOnHover,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            category,
            ...rest
        } = this.props;

        const url = EXTERNAL_LINKS[name];
        const content = children || url;
        const classes = classnames("components-external-link", className, {
            "no-underline": !underlineOnHover,
        });

        return (
            <a
                {...rest}
                href={url}
                target="_blank"
                className={classes}
                onClick={this.handleClick}
                ref={setRef}
            >
                {content}
            </a>
        );
    }
}
