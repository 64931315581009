const Key = {
    // regular keys
    TAB: 9,
    ENTER: 13,
    ESC: 27,
    SPACE: 32,
    PAGE_UP: 33,
    PAGE_DOWN: 34,
    HOME: 36,
    END: 35,
    BACKSPACE: 8,
    DELETE: 46,

    // directions
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
};

export default Key;
